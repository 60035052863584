<template>
  <div id="categories-list">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large
          >{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-dialog v-model="isDialogVisible" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveData">
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-select
                    v-model="row.cuenta"
                    :items="accountsList"
                    label="Cuenta"
                    dense
                    item-text="cuenta"
                    item-value="cuenta"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fecha"
                    dense
                    v-model="row.fecha"
                    :rules="[() => !!row.fecha || 'Este campo es requerido.']"
                    :error-messages="errorMessages"
                    ref="Fecha"
                    required
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Detalle"
                    dense
                    v-model="row.detalle"
                    :rules="[() => !!row.detalle || 'Este campo es requerido.']"
                    :error-messages="errorMessages"
                    ref="detalle"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Importe"
                    dense
                    v-model="row.importe"
                    :rules="[() => !!row.importe || 'Este campo es requerido.']"
                    :error-messages="errorMessages"
                    ref="importe"
                    required
                    type="number"
                    step="0.01"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-col cols="6" sm="6">
                    <v-select
                      v-model="row.proveedor"
                      :items="providersList"
                      label="Proveedor"
                      dense
                      item-text="nombre"
                      item-value="codigo"
                    ></v-select>
                  </v-col>
                  <!-- <v-text-field
                    label="Proveedor"
                    dense
                    v-model="row.proveedor"
                    :rules="[() => !!row.proveedor || 'Este campo es requerido.']"
                    :error-messages="errorMessages"
                    ref="proveedor"
                    required
                  ></v-text-field> -->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible = false"> Cerrar </v-btn>
            <v-btn type="submit" color="success">
              <!-- @click="isDialogVisible = false" -->
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- list filters -->
    <h2 class="">GASTOS</h2>
    <v-card>
      <div class="ml-2">
        <v-btn color="info" class="m-4" @click="showModal(1)">
          <v-icon center>{{ icons.mdiPlusCircle }} </v-icon>
          &nbsp;Gasto
        </v-btn>
        <br />
        <label for="">Filtrar:</label>
        <v-row>
          <v-col cols="4" sm="4" md="4">
            <label for="">Desde &nbsp</label>
            <input v-model="desde" type="date" id="desde" name="desde" />
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <label for="">Hasta &nbsp</label>
            <input v-model="hasta" type="date" id="hasta" name="hasta" />
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-btn color="info" class="" @click="filtrar()"> Filtrar </v-btn>
            &nbsp;
            <download-excel
              class="vv-btn"
              :data="items"
              :fields="json_fields"
              worksheet="My Worksheet"
              name="gastos.xls"
            >
              Descargar datos
            </download-excel>
            <!-- <label for="">Hasta &nbsp</label> -->
          </v-col>
        </v-row>
        <br />
        <!-- table -->
        <!-- v-model="selectedRows" -->
        <!-- :options.sync="options" -->
        <v-data-table :loading="loading" :headers="tableColumns" :items="items" :server-items-length="totalItems">
          <!-- name -->
          <!-- role -->
          <template #[`item.role`]="{ item }">
            <div class="d-flex align-center">
              <v-avatar
                size="30"
                :color="resolveUserRoleVariant(item.role)"
                :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`"
              >
                <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                  {{ resolveUserRoleIcon(item.role) }}
                </v-icon>
              </v-avatar>
              <span class="text-capitalize">{{ item.role }}</span>
            </div>
          </template>

          <!-- plan -->
          <template #[`item.currentPlan`]="{ item }">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.currentPlan }}</span>
          </template>

          <!-- status -->
          <template #[`item.status`]="{ item }">
            <v-chip
              small
              :color="resolveUserStatusVariant(item.status)"
              :class="`${resolveUserStatusVariant(item.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status }}
            </v-chip>
          </template>

          <!-- actions -->
          <template #[`item.acciones`]="{ item }">
            <v-btn color="success" medium @click="showModal(2, item)">
              <v-icon center dark> {{ icons.mdiPencil }} </v-icon>
            </v-btn>
            &nbsp;
            <v-btn color="error" medium @click="deleteData(item)">
              <v-icon center dark> {{ icons.mdiDeleteOutline }} </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiPencil, mdiPlusCircle } from '@mdi/js'

import { ref } from '@vue/composition-api'

import Vue from 'vue'

import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

// sidebar
// import UserListAddNew from './UserListAddNew.vue'
// import userStoreModule from '../userStoreModule'

// import useUsersList from './useUsersList'

export default {
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold },
      items: [],
      table: 'gastos',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Cuenta', value: 'cuenta', sortable: true },
        { text: 'Detalle', value: 'detalle', sortable: true },
        { text: 'Importe', value: 'importe', sortable: true },
        { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {},
      modalTitle: 'Nuevo gasto',
      accountsList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'Activo' }, { nombre: 'Inactivo' }],
      monedaLista: [{ moneda: 'Córdobas' }, { moneda: 'Dolar' }],
      providersList: [],
      desde: this.$moment().format('YYYY-MM-DD'),
      hasta: this.$moment().format('YYYY-MM-DD'),
      itemsBk: [],
      json_fields: {
        cuenta: 'cuenta',
        fecha: 'fecha',
        detalle: 'detalle',
        importe: 'importe',
        proveedor: 'nombreproveedor',
      },
    }
  },
  async mounted() {
    await this.getData()
    await this.getAccounts()
    await this.getproviders()
  },
  methods: {
    filtrar() {
      this.items = []
      this.totalRows = 0
      console.log(this.$moment.tz(this.fecha_ini, 'America/Costa_Rica').format('YYYY-MM-DD'))
      console.log(this.$moment.tz(this.fecha_fin, 'America/Costa_Rica').format('YYYY-MM-DD'))
      let filtroFecha = this.itemsBk.filter(
        x =>
          this.$moment.tz(x.fecha, 'America/Costa_Rica').format('YYYY-MM-DD') >=
            this.$moment.tz(this.desde, 'America/Costa_Rica').format('YYYY-MM-DD') &&
          this.$moment.tz(x.fecha, 'America/Costa_Rica').format('YYYY-MM-DD') <=
            this.$moment.tz(this.hasta, 'America/Costa_Rica').format('YYYY-MM-DD'),
      )
      this.items = filtroFecha
      this.totalRows = filtroFecha.length
      console.log('filtro por fecha ', filtroFecha)
    },
    exportar() {},
    async getproviders() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'proveedores/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.providersList = res
        }
      } catch (error) {
        this.providersList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async saveData() {
      try {
        let row = Object.assign({}, this.row)

        let res
        if (row.id) {
          //es update
          delete row.id
          delete row.comercio
          res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: row })
        } else {
          res = await this.$store.dispatch('post', { path: this.table + '/new/', data: this.row })
          //es post
        }
        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nuevo gasto'
        this.row = {}
        this.row.fecha = this.$moment().utc().format('YYYY-MM-DD')
      } else {
        this.row = item
        this.modalTitle = 'Editar gasto'
        this.row.fecha = this.$moment(this.row.fecha).utc().format('YYYY-MM-DD')
        delete this.row.nombreproveedor
      }
      this.isDialogVisible = true
    },
    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table + '/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.itemsBk = res
          this.totalItems = this.items.length
        } else {
          this.items = []
        }
      } catch (error) {
        this.items = []
        this.itemsBk = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getAccounts() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'catalogo_cuentas/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.accountsList = res
        }
      } catch (error) {
        this.accountsList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.vv-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  margin: 10px !important;
  cursor: pointer;
  background-color: rgb(48, 146, 48);
  color: white;
}
.v-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  margin: 5px !important;
  cursor: pointer;
}
</style>
